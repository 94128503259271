:root{
    --x: 40px;
    --vh: 1vh;

    --max-whidth: 500px;

    --padding-base: 20px;
    --margin-base: 20px;
    --transition-base: 0.3s linear;

    --border-radius: 10px;
    --border-radius-main: 20px;
    --loader-border-size: 2px;
    --card-border-radius: 1.25rem;
    --border-size: 2px;


    --avatar-size: 44px;
    --round-button-size: 44px;
    --browser-icon-size: 40px;
    --photo-size: 114px;
    --icon-size: 24px;
    --input-br: var(--border-radius);

    --card-pading: 0.5rem;

    --swiper-shift: 80px;

    --alert-animation-duration: 5s;

}