.input{
    width: 100%;
    /*position: relative;*/
    padding: 10px 40px 10px 16px;
    border-radius: var(--input-br);
    color: var(--color-input);
    text-overflow: ellipsis;
    caret-color: var(--color-primary);
    border: 2px solid var(--color-grey-light);
    background-color: var(--color-background-input);
    outline: none;
}

.input-highlight{
    box-shadow: 0 0 4px var(--input-highlight-color);
    transition: box-shadow .15s;
}

.inout::placeholder{
    color: var(--color-grey-light);
}

.date-input{
    padding-right: 12px;
}
