.banner{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: calc(0px - var(--padding-base));
    margin-right: calc(0px - var(--padding-base));
    gap: 2rem;
    padding: 3rem 1rem;
    background-color: var(--color-banner);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.banner-title{

    font-size: 3rem;
    font-weight: 900;
    line-height: 1;
}

.banner-button{
    padding: 0.5rem 1rem;
    align-self: center;
    color: white;
    font-size: 1rem;
    border: none;
    border-radius: 7px;
    background-color: black;
}