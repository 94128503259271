.place{
    display: flex;
    flex-direction: column;
    gap: 4px;

    position: relative;
    /*padding: var(--card-pading);*/
    box-shadow: var(--box-shadow);
    background-color: var(--color-background);
    border-radius: var(--card-border-radius);
    overflow: hidden;
}

.place-photo{
    border-radius: inherit;
    /*width: auto;*/
    /*height: auto;*/
    aspect-ratio: 1.7777;
    object-fit: cover;
}

.place .inner{
    padding: 0 12px 12px;
}

.place-title{
    padding-top: 8px;
    font-size: 1.125rem;
    font-weight: 600;
}

.place-rating{
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 0.875rem;
}

.place-icon{
    margin: 4px;
    width: calc(var(--icon-size) / 2);
    height: calc(var(--icon-size) / 2);
    color: var(--color-star);
}

.place-time{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 0.75rem;
}