.counter-value{
    padding-left: 12px;
    padding-right: 12px;
    font-weight: 600;
}

.counter-button{
    width: 32px!important;
    height: 32px!important;
}

.counter-button-icon{
    width: 12px!important;
    height: 12px!important;
}