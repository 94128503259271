.person{
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
}

.person-photo{
    flex: none;
}

.person-name{
    flex: 1;
    align-self: flex-start;
    color: var(--color-text);
    font-weight: 600;
}

.person-role{
    flex:0 1;
    align-self: flex-start;
    color: var(--color-text-light);
    font-size: 0.7rem;
}