.map-controls{
    /*color: var(--color-primary);*/
    z-index: 10;
}

.map-control-btn{
    width: var(--x);
    height: var(--x);
    border-radius: 50%;
    border: none;
    outline: none;
    background-color: var(--color-background);
    filter: var(--controls-shadow);
}

.map-control-btn svg{
    width: 18px;
    height: 18px;
}

.map-button, .map-button-location{
    position: relative;
    padding: 0;
    width: 45px!important;
    height: 45px!important;
}

.map-button svg{
    position: absolute;
    top: 50%;
    left: 50%;
}

.map-button-location svg{
    position: absolute;
    top: 40%;
    left: 40%;
}