.footer-btn-container{
    position: relative;
    padding: 10px 20px;
    background-color: var(--color-secondary);
    box-shadow: 0 -10px 30px 0 rgba(0, 0, 0, 0.10);
}

.full-screen-btn{
    padding: 16px 0;
    width: 100%;
    font-weight: 700;
    border-radius: 8px;
    border: none;

    color: var(--color-grey);
    background-color: var(--color-primary-light);

    overflow: hidden;
    transition: var(--transition-base);
    cursor: pointer;
}

.full-screen-btn.active{
    color: var(--color-primary-button);
    background-color: var(--color-primary);

}

.full-screen-btn:disabled{
    cursor: default;
    pointer-events: none;
    color: var(--color-grey);
    background-color: var(--color-primary-light);
}

