.time{
    position: relative;
    width: 100%;
}

.time-inner{
    padding: 10px 40px 10px 16px;
    padding-right: calc(var(--icon-size) + 12px);
    color: var(--color-input);
    font-weight: 600;
    background-color: var(--color-background-input);
    border-radius: var(--border-radius);
    border: var(--border);
}

.time-clock{
    position: absolute;
    top: 50%;
    right: 12px;
    color: var(--color-text-silver);
    transform: translateY(-50%);
}

.time-list{
    position: relative;
    width: 80px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    height: 12rem;
    background: var(--color-background);
    border: var(--border);
    border-radius: 4px;
}

.hh,.mm{
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
}


.time-list .time-item{
    padding: 4px;
}

.item{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    border-radius: 4px;
}

.time-list .time-item.selected .item{
    background-color: var(--color-primary);
    border: 1px solid var(--color-grey-light);
}
