.swiper-container{
    position: relative;
}

.swiper-controls{
    position: absolute;
    height: calc(100% - 2px);
    top: 1px;
    left: 1px;
    right: 1px;
    overflow: hidden;
    z-index: 1;
}

.swiper-button{
    position: relative;
    width: var(--swiper-shift);
    max-width: 50%;
    height: 100%;
    cursor: pointer
}


.swipe-item{
    position: relative;
    width: 100%;
    background-color:transparent;
    transition: transform .3s;
    z-index: 2;
}