.navigation-content{
    position: relative;
    box-shadow: 0 -10px 30px 0 rgba(0, 0, 0, 0.10);
    z-index: 5;
}

.navigation-item .navigation-icon{
    margin: 0 auto;
    width: var(--icon-size);
    height: var(--icon-size);
}

.navigation-item{
    width: 100%;
    padding-top: 8px;
    color: var(--color-grey);
    stroke: var(--color-grey);
    background-color: var(--color-navigation-bg);
    cursor: pointer;
}

.navigation-item + .navigation-item{
    margin-left: 1px;
}

.navigation-item .active{
    color: var(--color-primary);
    stroke: var(--color-primary);
}

.navigation-item .navigation-title{
    font-size: 0.625rem;
    font-weight: 400;
}
