.grid{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2,auto);
    grid-gap: 0 0.25rem ;
}

.scroll{
    overflow: scroll;
}

.dev-container{
    max-height: 100vh;
    overflow: scroll;
}