.tab {
    min-height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    width: 100%;
    min-width: 90px;
    text-align: center;
    color: var(--color-grey);
    font-weight: 600;

    border-bottom: 2px solid var(--color-grey);
    background-color: transparent;
    transition: all .3s ease-out;
    cursor: pointer;
}

.travel-tab-container{
    overflow-x: auto;
}

.tab.active{
    color: var(--color-primary);
    border-bottom: 2px solid var(--color-primary);

    cursor: default;
    pointer-events: none;
}