.icon-button {
    position: relative;
    padding: 12px 18px;
    color: var(--color-text);
    stroke: var(--color-text);
    border-radius: 2rem;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: var(--color-background);
}

.icon-button.border{
    border: var(--border);
}

.icon-button.bg-transparent{
    background-color: transparent;
}

.icon-button.bg-secondary{
    background-color: var(--color-secondary);
}

.icon-button.bg-primary{
    background-color: var(--color-primary);
}

.icon-button.bg-primary{
    background-color: var(--color-background);
}

.icon-button.shadow{
    box-shadow: var(--box-shadow);
}

.icon-button:disabled{
    color: var(--color-grey);
    fill: var(--color-grey);
    stroke: var(--color-grey);
    cursor: default;
    pointer-events: none;
}

.icon-button:active{
}

.icon-button.small{
    padding: 2px 10px;
}

.icon-button.center{
    width: 50px;
    height: 50px;
}

.icon-button.center .icon{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.icon-button .icon{
    position: relative;
    width: var(--icon-size);
    height: var(--icon-size);
}

.icon-button .icon svg,
.icon-button .icon img{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
