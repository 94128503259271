.photo-container{
    position: relative;
    /*padding-bottom: 85%;*/
    height: 100%;
    overflow: hidden;
    /*aspect-ratio: 2.0875;*/
}

.photo-image{
    display: block;
}

.photo-button-right,
.photo-button-left{
    width: var(--round-button-size);
    height: var(--round-button-size);
    position: absolute;
    top: 50%;
    opacity: 1;
    transform: translateY(-50%);
    transition: opacity .3s;
    background-color: var(--color-background);
    border: none;
    z-index: 2;
}

.photo-button-right:disabled,
.photo-button-left:disabled{
    color: var(--color-grey) !important;
}

.photo-container:hover .photo-button-right,
.photo-container:hover .photo-button-left,
.photo-container:hover .photo-index{
    opacity: 1;
}

.photo-button-right{
    right: 15px;
}

.photo-button-left{
    left: 15px;
    transform: translateY(-50%) rotate(-180deg);
}

.photo-index{
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: .7rem;
    opacity: 0;
}
