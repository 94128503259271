@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
@import url('variables.css');
@import url('colors.css');


html, body{
    height: calc(100 * var(--vh));
    overflow: hidden;
}

.disable-reload {
    overflow: hidden;
    overscroll-behavior: none;
}

a{
    color: inherit;
    text-decoration: none;
}

.relative{
    position: relative;
}

#root {
    height: 100%;
    overflow-y: hidden;
}

.hidden{
    visibility: hidden;
}

.title {
    font-weight: 600;
}

.title-bold{
    font-weight: 700;
}

.title-semi-bold{
    font-weight: 600;
}

.link{
    color: var(--color-link);
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
}

.flex-between {
    display: flex;
    justify-content: space-between;
}

.flex-end{
    display: flex;
    justify-content: flex-end;
}

.align-center{
    display: flex;
    align-items: center;
}

.row {
    display: flex;
    overflow-x: auto;
    flex-direction: row;
}

.row::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.flex-wrap{
    display: flex;
    flex-wrap: wrap;
}

.flex-nowrap{
    display: flex;
    flex-wrap: nowrap;
}

.flex-0{
    flex: 0 0 auto;
}

.flex-1{
    flex: 1 1 auto;
}

.w-full{
    width: 100%;
}

.h-full{
    height: 100%;
}

.column-revers{
    display: flex;
    flex-direction: column-reverse;
}

.column{
    display: flex;
    flex-direction: column;
}

.center{
    display: flex;
    justify-content: center;
    align-items: center;
}

.gap-1{
    gap: 1rem;
}

.gap-0\.75{
    gap: 0.75rem;
}

.gap-0\.5{
    gap: 0.5rem;
}

.gap-0\.25{
    gap: 0.25rem;
}

.flex-stretch {
    display: flex;
    justify-content: stretch;
}

.z-1{
    z-index: 1;
}


.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.content {
    flex: 1 1 auto;
    overflow-y: auto;
}

.footer {
    flex: 0 0 auto;
}


.img-abs {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.bb-2-grey {
    border-bottom: 2px solid var(--color-grey-light);
}

.pb-20{
    padding-bottom: 20px;
}

.pt-20{
    padding-top: 20px;
}

.mt-20{
    margin-top: 20px;
}

.mb-20{
    margin-bottom: 20px;
}

.ml-40{
    margin-left: 40px;
}

.pl-40{
    padding-left: 40px;
}


.footer-btn-container{
    position: relative;
    padding: 10px 20px;
    background-color: var(--color-background);
    box-shadow: 0 -10px 30px 0 rgba(0, 0, 0, 0.10);
    z-index: 2;
}

.fixed-bottom-button{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 20px 10px;
    background-color: transparent;
    z-index: 2;
}

.hide-scroll{
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.hide-scroll::-webkit-scrollbar {
    display: none;
    width: 0 !important
}

.number-hide-arrows::-webkit-outer-spin-button,
.number-hide-arrows::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;
}

.header-fixed{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
}

.icon{
    width: var(--icon-size);
    height: var(--icon-size);
}

.icon\/2{
    width: calc(var(--icon-size) / 2);
    height: calc(var(--icon-size) / 2);
}

.bg-grey-light{
    background-color: var(--color-grey-light);
}

.black{
    color: var(--color-black)!important;
    stroke: var(--color-black)!important;
}

.grey{
    color: var(--color-grey)!important;
    stroke: var(--color-grey)!important;
}



.small{
    font-size: .7rem;
}

.color-black{
    color: #000;
}

.photo{
    width: var(--photo-size);
    height: var(--photo-size);
    border-radius: var(--padding-base);
    object-fit: cover;
    overflow: hidden;
}

.block{
    padding-bottom: 20px;
}

.block + .block{
    padding-top: 20px;
}

.block:not(:last-child){
    border-bottom: 2px solid var(--color-grey-light);
}

input[type="date"]::-webkit-calendar-picker-indicator {
    color: var(--color-grey);
    display: block;
    vertical-align: center;
    background: url(../../public/icons/calendar_days.svg) no-repeat;
    width: 20px;
    height: 20px;
}

.br-left-0{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.br-right-0{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.avatar{
    position: relative;
    width: var(--avatar-size);
    height: var(--avatar-size);
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
}

.overflow-x-hidden{
    overflow-x: hidden;
}

.overflow-y-hidden{
    overflow-y: hidden;
}

.location-marker{
    width: 32px;
    height: 32px;
    background-image: url('../../public/icons/location_on_24px.svg');
}

/*.loader{*/
/*    width: 40px;*/
/*    height: 40px;*/
/*    color: var(--color-grey);*/
/*}*/

#map{
    height: 100%;
    overflow: hidden;
}

.map-controls{
    position: absolute;
    bottom: 20%;
    right: 12px;
}

.share-link{
    position: relative;
    width: 44px;
    height: 44px;
    border-radius: 50%;
}

.share-link svg{
    position: absolute;
    left: 50%;
    top: 50%;
    width: var(--icon-size);
    height: var(--icon-size);
    transform: translate(-50%, -50%);
}

.whatsapp-bg{
    background-color: var(--color-whatsapp);
}

.telegram-bg{
    background-color: var(--color-telegram);
}

.share-link.telegram-bg > *{
    transform: translate(-55%, -50%);
}

.share-link-bg{
    background-color: var(--color-share-link);
}

.rounded-button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--control-button-size);
    height: var(--control-button-size);

    background-color: var(--color-primary);
    border-radius: 50%;
    border: none;
    outline: none;
    filter: var(--controls-shadow)
}

.rounded-button:disabled{
    background-color: var(--color-primary-light);
}


.close-button{
    color: var(--color-primary) !important;
    background-color: transparent!important;
}

/*[class*='copyrights'],*/
[class*='ymaps-2-1-79-controls']{
    visibility: hidden;
}

[class*='copyrights']{
    pointer-events: none!important;
}

.control-button{
    width: var(--icon-size);
    height: var(--icon-size);
}

.balloon-header{
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: 1.2rem;
    color:var(--color-text);
}

.balloon-body{
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    gap: 0.25rem;

    font-weight: 400;
    font-size: 1rem;
    color:var(--color-text);
}

.balloon-image{
    position: relative;
    /*flex-basis: 40%;*/
    padding-top: 56%;
    overflow: hidden;
}

.balloon-content{
    display: flex;
    flex-direction: column;
    line-height: 1.5;
}

.balloon-footer{
    border-top: 2px solid var(--color-grey-light);
    font-weight: 400;
    font-size: 0.7rem;
    color:var(--color-text);
}

.overflow-hidden{
    overflow: hidden;
}

.overflow-scroll{
    overflow: scroll;
}


.map-detail-route-button{
    position: absolute;
    z-index: 10;
    cursor: pointer;
}

.rounded-button{
    padding: 12px 10px;
    background: transparent;
    border-radius: 2rem;
    border: 2px solid var(--color-text-silver);
}

.rounded-btn{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    width: var(--round-button-size);
    height: var(--round-button-size);
    background: var(--color-background);
    border: none;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
}

.chat-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background: transparent;
    border: 2px solid var(--color-text-silver);
    overflow: hidden;
    cursor: pointer;
}

.border{
    border: var(--border);
}