.profile-backside{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    height: 100%;

    overflow-y: auto;
    z-index: 0;
}

.profile-image img{
    position: relative;
    /*margin: 0 auto;*/
    width: var(--photo-size);
    height: var(--photo-size);
    border-radius: var(--border-radius);
    object-fit: cover;
    overflow: hidden;
}

.profile-user-name{
    font-size: 1rem;
    font-weight: 700;
}



.auth-item:first-child{
    margin-top: 20px;
}

.auth-info{
    font-weight: 500;
    font-size: .7rem;
    line-height: 1.2;
}

.indistinct{
    color: var(--color-grey);
    font-weight: 400;
    font-size: .5rem;
}
