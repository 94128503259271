.current-travel{
    color: black;
    background: #E6EAE9;
    z-index: 0;
}

.current-travel-header{
    margin: 0.5rem var(--margin-base);
    color: var(--color-text);
    z-index: 10;
}

.current-travel-header.copen .menu-dots,
.current-travel-header.copen{
    color: black;
    fill: black;
}

.current-travel-content{
    z-index: 0;
}



.current-travel-header.transparent{
    background: transparent;
}


.current-travel-icons{
    display: flex;
    gap: 8px;
    color: var(--color-text);
}

.current-travel-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
}

.current-travel-image{
    aspect-ratio: 1.25;
}

.current-travel-title{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 28px;
    font-size: 2rem;
}

.current-travel-title .icon{
    color: var(--color-text-silver);
}

.current-travel-title .icon.visible{
    color: var(--color-success);
}

.current-travel-title.public .icon{
    color: var(--color-success);
}

.current-travel-subtitle{
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-text-silver);
    font-size: 0.75rem;
}

.current-travel-duration{
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.current-travel-members{
    margin-top: 1rem;
}

.current-travel-btns{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;

    padding: 0 0 calc(var(--padding-base) * 2);
}

.current-travel-btns .c1{
    grid-column: 1/3;
}

.current-travel-btns .c2{
    grid-column: 3/5;
}

.current-travel-btns .c3{
    grid-column: 5/6;
}

.route-content{
    padding-top: var(--padding-base);
}

.route-filter-list{
    display: flex;
    justify-content: space-between;
    gap: 0.75rem;
}

.route-filter-btn{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    font-size: 0.75rem;
}

.route-tabs{
}

@media (min-width: 460px) {
    .current-travel-btns{
        justify-content: center;
        gap: 1rem;
    }

    .route-filter-list{
        justify-content: center;
        gap: 1rem;
    }

}

.all-places{
    padding-top: var(--padding-base);
    display: flex;
    flex-direction: column;
    gap: 1rem;
}