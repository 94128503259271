.textarea{
    padding-top: 10px;
    padding-right: 12px;
    padding-bottom: 10px;
    padding-left: 16px;
    width: 100%;
    caret-color: var(--color-primary);

    border-top-left-radius: var(--input-br);
    border-bottom-left-radius: var(--input-br);
    border-top-right-radius: var(--input-br);
    border-bottom-right-radius: var(--input-br);

    border: 2px solid var(--color-grey-light);
    background-color: var(--color-background);
    outline: none;
    resize: none;
}

.textarea::placeholder{
    color: var(--color-grey);
}