.expenses{
}

.expense-section{

}

.expense-section-main{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.125rem;
    font-weight: 600;
}

.expense-section-name{

}

.expense-section-total{

}

.expense-section-line{
    position: relative;
    height: 4px;
    border-radius: 4px;
    overflow: hidden;
    background-color: var(--color-grey-light);
}

.expense-section-line-front{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
}

.expense-section-line-front.green{
    background-color: var(--color-success);
}

.expense-section-line-front.yellow{
    background-color: var(--color-warning);
}

.expense-section-line-front.red{
    background-color: var(--color-danger);
}

.expense-section-secondary{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.75rem;
    color: var(--color-text-silver);
}

.expense-section-limit,
.expense-section-rest{
    color: var(--color-text-silver);
    font-size: 0.75rem;
}

.expense-section-rest.red{
    color: var(--color-danger);
}

.expenses-list{
    margin-top: 1rem;
    /*display: flex;*/
    /*max-height: 9999px;*/
    flex-direction: column;
    gap: 0.75rem;
    transition: max-height 0.3s ease-out;
    overflow: hidden;
}

.expenses-list.collapsed{
    max-height: 0;
}

.expense-item{
    background-color: var(--color-background);

}

.flex-between{

}

.expense-value{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.expense-title,
.expense-value .origin{
    font-size: 0.875rem;
    font-weight: 600;
}

.expense-value .converted{
    font-size: 0.7rem;
    color: var(--color-text-silver);
}


.expense-date{
    color: var(--color-text-silver);
    font-weight: 600;
    font-size: 0.7rem;
}

.collapse-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-text-silver);
    background-color: var(--color-background);
    /*border: 2px solid var(--color-text-silver);*/
    /*border-radius: 4px;*/
}

.collapse-btn .icon{
    transform: rotate(-90deg);
    transition: transform 0.3s linear;
}

.collapse-btn.collapsed .icon{
    transform: rotate(90deg);
}


