.danger-border{
    border: 2px solid var(--color-danger);
}

.expense-input{
    padding-right: 50px;
}

.expense-symbol{
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    color: var(--color-black);

}