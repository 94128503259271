.menu {
    position: relative;
    width: var(--icon-size);
    height: var(--icon-size);

    color: var(--color-text);
    transition: color .3s, stroke .3s, fill .3s;
    z-index: 2000;
}

.menu-container {
    padding: 20px;
    position: fixed;
    top: 0;
    right: 0;

    background-color: var(--color-background);

    transition: max-height 1s;
    overflow: hidden;
}

.menu .menu-container {
    max-width: var(--icon-size);
    max-height: var(--icon-size);
    border: transparent;
    pointer-events: none;
    opacity: 0;

}

.menu.open .menu-container {
    min-width: 50vw;
    max-width: 100vw;
    max-height: 100vh;
    border-radius: 5px;
    box-shadow: 0 0 8px hsla(0, 0%, 80%, .8);
    opacity: 1;
    pointer-events: all;
}

.menu-dots {
    position: absolute;
    right: 0;
    top: 0;
    opacity: 1;
    pointer-events: all;
}

.menu.open .menu-dots {
    opacity: 0;
    pointer-events: none;
}

.menu-icons {
    /*color: var(--color-icon);*/
    stroke: var(--color-icon);
    fill: var(--color-icon);
    cursor: pointer;
}

.menu-icons svg,
.menu-dots {
    width: var(--icon-size);
    height: var(--icon-size);

}

.menu-icons svg:hover {
    stroke: var(--color-primary);
    fill: var(--color-primary);
}

.menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    padding: .5rem;
    cursor: pointer;
}

.menu-item:hover {
    color: var(--color-primary);
}

.menu-item-icon{

}

