.travel-settings-hotel,
.travel-settings-appointment{
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    padding-right: 12px;
    background-color: var(--color-primary-light);
    border-radius: var(--br-10);
}

.travel-settings-hotel-rent{
    color: var(--color-grey);
}

.buttons-block{
    position: fixed;
    left: 20px;
    right: 20px;
    bottom: 85px;
    width: auto;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.travel-settings-group-list{
    padding-left: 2rem;
}
