.travel-card-swiper{
    color: var(--color-background);
}

.travel-item{
    position: relative;
    padding: 4px;
    color: var(--color-text);
    border-radius: var(--border-radius);
    background-color: var(--color-background);
    box-shadow: var(--box-shadow);
    /*overflow: hidden;*/
}

.travel-item-container{
    display: flex;
    justify-content: stretch;

}


.travel-image{
    position: relative;
    /*margin: -4px;*/
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 1rem;
}

.travel-content{
    position: relative;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: stretch;
    width: 100%;
    /*align-items: flex-start;*/
    padding: 4px 0;
    overflow: hidden;
}

.travel-movement{
    margin-top: auto;
    overflow-x: hidden;
}

.travel-movement.tagsScrolling{
    overflow-x: auto;
}

.travel-button{
    padding: 2px 8px;
    color: var(--color-text);
    font-size: .8rem;
    background-color: var(--color-secondary);

    border: 2px solid var(--color-grey);
    border-radius: 1rem;
    cursor: pointer;
}

.travel-title{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.travel-card-icon{
    height: 100%;
    width: var(--swiper-shift);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--color-text);
    background: var(--color-primary-light);
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    overflow: hidden;
}