.session{
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    padding: 8px;
    border-radius: 4px;
}

.session.active{
    background-color: var(--color-background-alt);
}

.session-browser{
    grid-column: 1 / 3;
    grid-row: 1 / 3;
}

.session-browser {
    width: var(--browser-icon-size);
    height: var(--browser-icon-size);
    overflow: hidden;
}

.session-browser.chrome {
    background: url("../../../public/images/browsers.png") 0 0;
}

.session-browser.edge {
    background: url("../../../public/images/browsers.png") -40px 0;
}

.session-browser.firefox {
    background: url("../../../public/images/browsers.png") -80px 0;
}

.session-browser.opera {
    background: url("../../../public/images/browsers.png") -120px 0;
}

.session-browser.safari {
    background: url("../../../public/images/browsers.png") -160px 0;
}

.session-browser.other {
}


.session-ip{
    grid-column: 3 / 9;

}

.session-ip span{
    font-size: 0.7rem;
    color: var(--color-text-silver);
}

.session-location{
    color: var(--color-text-silver);
    font-size: 0.7rem;
    text-align: right;
    grid-column: 9 / -1;
}

.session-time{
    color: var(--color-text-silver);
    font-size: 0.7rem;
    grid-column: 3 / -1;
}

.session-btn{
    color: var(--color-primary);
    background-color: inherit;
    border: none;
    outline: none;
    cursor: pointer;
}
