.date{
    position: relative;
}

.date-header{
    width: 100%;
}

.date-header{
    position: relative;
    padding: 12px;
    color: var(--color-input);
    font-weight: 600;
    background-color: var(--color-background-input);
    border-radius: var(--border-radius);
}

.date-text{
    /*padding-right: 50px;*/
    max-width: calc(100% - 50px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.date-icon{
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    height: 100%;
    max-height: 50px;
    top: 50%;
    right: 0;
    aspect-ratio: 1/1;
    transform: translateY(-50%);
}

.dp{
    position: absolute;
    padding: 0.25rem;
    border: var(--border);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    background-color: var(--color-background);
    z-index: 100;
}

.dp-header{
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}

.dp-arrow{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.dp-arrow.left{
    grid-column: 1 / 2;
    transform: rotate(180deg);
}

.dp-arrow.right{
    grid-column: 7 / 8;
}


.dp-title{
    grid-column: 2 / 7;
    font-weight: 600;
    text-align: center;
}

.dp-calendar{
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}

.dp-item{
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1 / 1;
}

.dp-item.selected{
    border-radius: 0.25rem;
    background-color: var(--color-primary);
}

.dp-footer{
    display: flex;
    /*flex-direction: column;*/
    /*align-items: flex-end;*/
    justify-content: space-between;
}

.dp-btn{
    padding: 0.5rem 1rem;
    color: var(--color-primary);
    background-color: var(--color-background);
    border: none;
    outline: none;
}
